import {websoket} from "./utilities/websoket.js";
import {fetchRequest} from "./utilities/fetchRequest.js";
import $ from 'jquery';

window.$ = $;
window.jQuery = $;

const loginTip = require('tpl/login/loginTip.html');
const $captcha = $('._captcha');

const __login = {
    timer: null,
    token: '',
    showType: '',
    smsType: '1',
    init() {
        this.showType = $('._showType').data("show");
        __login.initCaptcha();
        // 默认显示密码登录
        if ($('._saoCodeLogin').length) {
            // 初始化二维码登录
            this.reloadLoginCode();
        }
        // 只显示短信登录
        if (this.showType == 1) {
            this.loginTab.call($('._loginTab').eq(0));
            $('._loginTab').eq(1).hide();
            // this.initCaptcha();
            __login.initCaptcha.refresh && __login.initCaptcha.refresh();
            return false;
        }
        if ($(".m-login-register").length) {
            // this.initCaptcha();
            __login.initCaptcha.refresh && __login.initCaptcha.refresh();
            return false;
        }

        this.loginTab.call($('._loginTab').eq(1));
        this.initRememberStatus();
        
    },
    queryString(name, url = window.location.href) {
        if (name !== undefined) {
            name = name.replace(/[\[\]]/g, "\\$&");

            let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);

            if (!results) {
                return null;
            }

            if (!results[2]) {
                return '';
            }

            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }

        let pairs = location.search.slice(1).split('&'),
            result = {};

        if (pairs === ['']) {
            return {};
        }

        for (let i = pairs.length - 1, pair; i > -1; i--) {
            pair = pairs[i].split('=');
            result[pair[0]] = decodeURIComponent(pair[1] || '');
        }

        return JSON.parse(JSON.stringify(result));
    },
    soketOnmessage() {
        let callbackUrl = $('._callbackUrl').val(),
            historyUrl = callbackUrl !== '' ? callbackUrl : document.referrer;

        window.location.href = '/index/ajaxQrCodeLogin?token=' + __login.token + '&callback=' + historyUrl;
    },
    registerTabChangeFun(){
        const idx = $(this).index();

        $(this).addClass('active').siblings().removeClass('active');
        $('._registerBtn').eq(idx).show().siblings().hide();
        $('.register-tab span').eq(idx).show().siblings().hide();
    },
    changeLoginTab(){

        $('._loginTab').removeClass('active').eq(1).addClass('active');
        $('._styleType').hide().eq(1).show();
    },
    loginTab() {
        let $this = $(this),
            idx = $this.index(),
            showIdx = $this.data('type') == 'login' ? 0 : 2,
            isRemoveCaptcha = false;

        $('._voiceTip').hide();
        $this.addClass('active').siblings().removeClass('active');
        $('._styleType').eq(idx).show().siblings('._styleType').hide();
        $('._inputFocus').val('')
            .parent('._changeStatus')
            .removeClass('z-active-pink z-active-blue');
        $(".m-login-phHint").hide();
        if (isRemoveCaptcha) {
            $captcha.remove();
        } else {
            isRemoveCaptcha = true;
        }

        $('._styleType').eq(idx).find('._appendCaptcha').after($captcha);
        if (idx == showIdx) {
            // $captcha.removeClass('f-hide');
            __login.initCaptcha.refresh && __login.initCaptcha.refresh();
        } else {
            $captcha.addClass('f-hide');
        }
        if(idx == 2){
            __login.initRememberStatus();
        }
    },
    conditionChecked() {
        let $this = $(this);

        $this.hasClass('z-checked') ? $this.removeClass('z-checked') : $this.addClass('z-checked');
    },
    validPhone(str, zipCode) {
        // let zipCode = $("._telCode").val();

        if (zipCode === "86") {
            return /^1[0-9]{10}$/.test(str);
        } else {
            return true;
        }
    },
    validatorCaptcha() {
        // 根据当前选中的类型判断要从哪一个input中获取手机号
        let verificationTelCls = $("._loginTab.active").index() == 1 ? "._verificationTel" : "._passwordTel";

        let phone = $(verificationTelCls).val();
        let [code] = [$('._telCode').val()];

        if ($('._loginTab.active').text() == '密码登录') {
            phone = $('._passwordTel').val();
        }

        if (!__login.validPhone(phone, code)) {
            if ($('#selectionFlag').val() == 1) {
                __login.loginTipFun($(verificationTelCls), 'wrong', '请输入正确的账号');
            } else {
                __login.loginTipFun($(verificationTelCls), 'wrong', '手机号错误');
            }

            // __login.initCaptcha();
            // setTimeout(function () {
            //     __login.initCaptcha.refresh && __login.initCaptcha.refresh();
            // }, 300)
            return false;
        }
        // 注册页面验证手机号是否注册
        if ($('._regiesterPage').length > 0) {
            __login.isRegister($(verificationTelCls), phone, code);
        }
        // 如果选中的短信登陆。就发送验证码
        if ($("._loginTab.active").index() == 2) {
            __login.sendMsgCode();
        }
    },
    initCaptcha() {
        // initNECaptcha为全局函数，可直接调用
        initNECaptcha({
            // config对象，参数配置
            captchaId: '5be5a701dfef49faa1e0e786ecd3647b',
            element: '#captcha',
            onVerify: function (err, data) {
                __login.validatorCaptcha();
            }
        }, function (instance) {
            __login.initCaptcha = instance;
            // 初始化成功后得到验证实例instance，可以调用实例的方法
        }, function (err) {
            // 初始化失败后触发该函数，err对象描述当前错误信息
        });
    },
    isRegister($this, tel, code) {
        fetchRequest({
            url: '/common/ajaxCheckMobile',
            type: 'post',
            parma: {
                mobile: tel,
                country_code: code
            },
            successFun({data}) {

                data.uid == 0 ? __login.loginTipFun($this, 'right', false) : __login.loginTipFun($this, 'wrong', '该手机号已注册，请登录后从个人中心进行身份认证/入驻 ',`/${location.search}`);
                // data.uid == 0 ? __login.loginTipFun($this, 'right', false) : __login.loginTipFun($this, 'wrong', '该手机号已经注册过', location.search);

            }
        });
    },
    sendMsgCode() {
        let $this = $("._sendMsgCode");
        let validFormInputCls = {
            'mobile': '._verificationTel',
            'verify_code': '.yidun_input',
            'code_type': '._sendMsgCode',
            'callback': '._callbackUrl',
        };

        if ($this.hasClass('m-login-codeBtn-disabled')) {
            return false;
        }

        __login.submitDataHandle(5, '/common/ajaxSendCode', 'post', validFormInputCls, function () {

            layer.msg('验证码已发送,请注意查收');
            __login.timeout($this);
            $('._voiceTip').show();
            __login.smsType = '1';
        }, function (code) {
            if (code == '100070015') {
                let txt = '请先进行人机交互',
                    $ele = $('#captcha');

                __login.initCaptcha.refresh && __login.initCaptcha.refresh();

                __login.loginTipFun($ele, 'wrong', txt);
            }

        });
    },
    timeout(obj) {
        let iNow = 60;

        clearInterval(__login.timer);
        __login.timer = setInterval(() => {
            iNow--;
            obj.html(iNow).addClass('m-login-codeBtn-disabled');
            if (!iNow) {
                obj.html('重新发送').removeClass('m-login-codeBtn-disabled');
                clearInterval(__login.timer);
                // __login.initCaptcha();
                __login.initCaptcha.refresh && __login.initCaptcha.refresh();
            }

        }, 1000);
    },
    changeStatus() {
        $(this).removeClass('z-active-pink z-active-blue')
            .find('.m-login-phTip, .m-login-phHint')
            .remove();
    },
    inputFocus() {
        $(this).parents("._changeStatus").removeClass('z-active-pink z-active-blue')
            .find('.m-login-phTip, .m-login-phHint')
            .remove();
    },
    inputBlur() {
        let $this = $(this);
        let [val, type] = [$this.val(), $this.data('type')];
        let txt = '不能为空';
        let code = $(this).parent().find('._telCode').val();

        if (type == 'tel') {
            if ($('#selectionFlag').val() == 1) {
                txt = '请输入正确的账号';
            } else {
                txt = '手机号错误';
            }

        }

        if (!val || (type == 'tel' && !__login.validPhone(val, code))) {
            __login.loginTipFun($this, 'wrong', txt);
            return false;
        }

        $('._loginPasswordBtn').removeClass('_lock');
        // __login.initCaptcha();

        if ($captcha.length && type == 'tel') {
            __login.initCaptcha.refresh && __login.initCaptcha.refresh();
        }
    },
    reloadLoginCode() {
        fetchRequest({
            url: '/index/getQrCodeUrl',
            type: 'get',
            successFun({data}) {
                __login.token = data.token;

                $('._fCodeFail').hide();
                $('._saoCodeImg').attr('src', data.url);

                websoket({
                    msg: `{"cmd":"QrcodeLogin","p":{"lgToken":"${data.token}"}}`,
                    onmessagefun: __login.soketOnmessage
                });
            }
        });
    },
    loginTipFun($ele, type, tip, url) {
        let className = type == 'wrong' ? 'z-active-pink' : 'z-active-blue';
        let tips = loginTip({
            type: type,
            tip: tip,
            url: url
        });

        $ele.parent('._changeStatus')
            .addClass(className)
            .append(tips);
    },
    submitDataHandle(submitType, url, type, submitInputclsArr, callBack, errorCallBack) {
        // type = 1 密码登录 2短信登录 3注册 4找回密码
        // 最后需要提交到后台的数据
        let countryCode = '';
        let telColdeList = $('._telCode');

        telColdeList.each((index, item) => {
            if (!$(item).is(':hidden')) {
                countryCode = $(item).val();
            }
        });
        let submitData = {};
        // 全部验证通过标识
        let continueFlag = true;

        for (let key in submitInputclsArr) {
            let $ele = $(submitInputclsArr[key]);
            let val = $ele.val();

            if (submitInputclsArr[key] == '._sendMsgCode') {
                submitData[key] = $ele.data('type');
                continue;
            }

            if (submitInputclsArr[key] == '._callbackUrl') {
                submitData['callback'] = $ele.val();
                continue;
            }

            if (submitInputclsArr[key] == '._passwordVCode' && !val) {
                __login.loginTipFun($ele, 'wrong', '密码不能为空');
                continue;
            }
            if (submitInputclsArr[key] == '._passwordCode' && val.length < 8) {
                __login.loginTipFun($ele, 'wrong', '密码长度不能小于8位');
                continue;
            }

            if (submitInputclsArr[key].indexOf('Tel') != -1 && !__login.validPhone(val, countryCode)) {
                if ($('#selectionFlag').val() == 1) {
                    __login.loginTipFun($ele, 'wrong', '请输入正确的账号');
                } else {
                    __login.loginTipFun($ele, 'wrong', '手机号错误');
                }
                continueFlag = false;

            } else if (!val && submitType == 1 && submitInputclsArr[key].indexOf('yidun_input') != -1) {
                let $pwdLoginCon = $("._psdLogin").parent();

                // 如果密码登录的云盾已经显示出来了。就对此项进行校验 
                if (!$pwdLoginCon.find("._captcha").hasClass("f-hide")) {
                    $ele = $pwdLoginCon.find('#captcha');
                    __login.loginTipFun($ele, 'wrong', '请先进行人机交互');
                    continueFlag = false;
                } else {
                    submitData[key] = '';
                }
            } else if (!val) {
                let txt = '不能为空';

                if (submitInputclsArr[key].indexOf('yidun_input') != -1) {
                    txt = '请先进行人机交互';
                    $ele = $(submitInputclsArr[key]).parents('#captcha');
                }
                $('._captcha').removeClass('f-hide').show();
                __login.loginTipFun($ele, 'wrong', txt);
                continueFlag = false;
            } else {
                submitData[key] = $ele.val();
            }

        }
        if (submitType == 1) {
            // 记住我
            if ($('._rememberMe').hasClass('z-checked')) {
                submitData['is_remember'] = 1;
            }
        } else {
            submitData['type'] = __login.smsType;
        }

        if (submitType == 3) {
            // 注册没有同意协议
            if (!$('._agreement').hasClass('z-checked') && continueFlag) {
                layer.msg('请先阅读并同意');
                continueFlag = false;
            }
            submitData['agree'] = 1;
            $('._registerBtn:visible').data('type') == 'org' ? submitData.jump_type = 1 : ''; // 组织入驻
        }
        if (submitType == 4 && submitInputclsArr.pass) {
            if ($('._resetPsd').val().length < 8) {
                this.loginTipFun($('._resetPsd'), 'wrong', '密码必须至少包含8个字符，且至少包含以下四种类型字符中的两种(数字、大写字母、小写字母和特殊符号)');
                continueFlag = false;
            }
            if ($('._resetPsd').val() != $('._resetPsdAgain').val() && continueFlag) {
                this.loginTipFun($('._resetPsdAgain'), 'wrong', '两次密码不一致');
                continueFlag = false;
            }
        }

        submitData['country_code'] = countryCode;
        // 如果所有的选项都已经正确输入
        if (!continueFlag) {
            return false;
        }
        if (submitType == 1 || submitType == 2) {
            submitData['bg'] = __login.queryString('bg') || 0;
        }


        var b = new __login.Base64();

        if (submitData.pass) {
            submitData.pass = b.encode(submitData.pass);
        }

        if (submitData.pass_confirm) {
            submitData.pass_confirm = b.encode(submitData.pass_confirm);
        }
        let index = layer.load(2, {time: 5000});

        fetchRequest({
            url: url,
            type: type,
            parma: submitData,
            successFun(json) {
                layer.close(index);
                callBack && callBack(json);
            },
            codeError({code, data, msg}) {
                layer.close(index);
                errorCallBack && errorCallBack(code, data, msg);
            }
        });
    },
    loginPasswordBtn() {
        // 密码登录
        let validFormInputCls = {
            'mobile': '._passwordTel',
            'pass': '._passwordCode',
            'verify_code': '.yidun_input',
            'callback': '._callbackUrl'
        };
        let $this = $(this);

        __login.submitDataHandle(1, '/index/ajaxLoginByPass', 'post', validFormInputCls, (data) => {
            // 有错误就弹出提示
            if (data.code != 0) {
                layer.msg(data.msg);
                return false;
            }

            // 记住密码
            if ($('._rememberMe').hasClass('z-checked')) {
                __login.setCookieFun('user', $('._passwordTel').val());
                __login.setCookieFun('pass', $('._passwordCode').val());
            } else {
                __login.setCookieFun('user', '', -1);
                __login.setCookieFun('pass', '', -1);
            }

            window.location.href = data.data.jump_url;
        }, (code, data, msg) => {

            // 显示重置密码弹窗
            if (code == '100200121') {
                __login.showChangePassModal();
                return false;
            }

            if (code == '100070016' || code == '100070015' || (code == '100200101' && data.show_verify == 1)) {
                if (!$('._captcha').is(":hidden")) {
                    __login.initCaptcha.refresh && __login.initCaptcha.refresh();
                } else {
                    $('._psdLogin').eq(0).after($captcha);
                    $this.css('marginTop', '40px');
                    $('._captcha').removeClass('f-hide');
                    __login.initCaptcha();
                }
            }


        });
    },
    setCookieFun(name, value) {
        const date = new Date();

        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
        document.cookie = name + "=" + escape(value) + ";expires=" + date.toGMTString();

    },
    getCookieFun(name) {
        let cStart = '',
            cEnd = '';

        if (document.cookie.length > 0) {

            cStart = document.cookie.indexOf(name + "=");

            if (cStart != -1) {
                cStart = cStart + name.length + 1;
                cEnd = document.cookie.indexOf(";", cStart);

                if (cEnd == -1) {
                    cEnd = document.cookie.length;
                }

                return decodeURI(document.cookie.substring(cStart, cEnd));
            }
        }

        return "";
    },
    initRememberStatus() {
        let user = this.getCookieFun('user'),
            pass = this.getCookieFun('pass');

        if (user) {
            $('._rememberMe').addClass('z-checked');
        }
        user !== '' ? $('._passwordTel').val(user) : '';
        pass !== '' ? $('._passwordCode').val(pass) : '';

    },
    loginCodeBtn() {
        let $this = $(this);
        let url = '';
        let validFormInputCls = {};

        // 短信验证码登录
        if (__login.showType == 1) {
            url = '/index/thirdProtect';
            validFormInputCls = {
                'country_code': '._telCode',
                'mobile': '._verificationTel',
                'msg_code': '._verificationCode',
                'pass': '._passwordVCode',
                'callback': '._callbackUrl'
            };
        } else {
            url = '/index/ajaxLoginByCode';
            validFormInputCls = {
                'mobile': '._verificationTel',
                'msg_code': '._verificationCode',
                'callback': '._callbackUrl'
            };
        }
        __login.submitDataHandle(2, url, 'post', validFormInputCls, (data) => {
            $this.removeClass('_lock');

            if (data.code != 0) {
                layer.msg(data.msg);
                return false;
            }

            window.location.href = data.data.jump_url;
        }, (code) => {
            // 显示重置密码弹窗
            if (code == '100200121') {
                __login.showChangePassModal();
                return false;
            }
        });
    },
    showChangePassModal(){
        layer.open({
            type: 1,
            title: false,
            skin: 'layui-layer-demo',
            shadeClose: false,
            closeBtn: 0,
            shade: 0.8,
            // area: ['300px', 'auto'],
            content: '  <div class="change-pass-modal">\
                            <p>您的账号长时间未登录,</p>\
                            <p>为保障账号安全，请您修改密码</p>\
                            <a href="/findPassword" target="_blank">好的，去修改密码</a>\
                        </div>'
        });

    },
    registerBtn() {
        // 注册提交
        let validFormInputCls = {
            'mobile': '._verificationTel',
            'msg_code': '._verificationCode',
            'pass': '._registerPsd',
            'callback': '._callbackUrl',
        };

        __login.submitDataHandle(3, '/index/ajaxRegByMobile', 'post', validFormInputCls, (data) => {
            if (data.code != 0) {
                layer.msg(data.msg);

                return false;
            }

            window.location.href = data.data.jump_url;
        });
    },
    findPsdNextBtn() {
        // 找回密码
        let step = $(this).data('step');
        let validFormInputCls = {
            'mobile': '._verificationTel',
            'msg_code': '._verificationCode',
            'callback': '._callbackUrl'
        };

        // 第一步
        if (step == 1) {
            __login.submitDataHandle(4, '/common/ajaxVerifyCode', 'post', validFormInputCls, ({data}) => {
                // 提交后台验证
                $('._uniqueCode').val(data.unique_code);
                $(".m-login-pwdType1").hide();
                $(".m-login-pwdType2").show();
                $(".m-login-pwdPro>span.m-login-pro2").addClass("active").siblings().removeClass("active");
                $(".m-login-pwd>img").attr("src", "../../vendors/images/login/login-process2.jpg");
            });

        }
        if (step == 2) {
            let validFormInputCls = {
                'mobile': '._verificationTel',
                'pass': '._resetPsd',
                'pass_confirm': '._resetPsdAgain',
                'unique_code': '._uniqueCode'
            };

            __login.submitDataHandle(4, '/index/ajaxResetPwd', 'post', validFormInputCls, () => {
                $(".m-login-pwdType2").hide();
                $(".m-login-pwdType3").show();
                $(".m-login-pwdPro>span.m-login-pro3").addClass("active").siblings().removeClass("active");
                $(".m-login-pwd>img").attr("src", "../../vendors/images/login/login-process3.jpg");
            });
        }
    },
    bindTelBtn() {
        let validFormInputCls = {
            'mobile': '._verificationTel',
            'msg_code': '._verificationCode'
        };

        function next() {
            window.location.href = "/strong/index?is_login_zt=new";
            // __login.submitDataHandle(5, '/sso/ajaxBindMobile', 'post', validFormInputCls, () => {
            //     layer.msg('绑定成功');
            //     setTimeout(() => {
            //         window.location.href = '/';
            //     }, 1000);
            // });
        }

        __login.submitDataHandle(5, '/common/ajaxCheckMobileBind', 'get', validFormInputCls, ({code, data}) => {
            if (code == 0 && data.use_status == 1) {
                layer.confirm('该手机号已注册，请更换其他手机号或重新登录？', {
                    btn: ['取消', '重新登录']
                }, () => {
                    window.location.href = '/';
                }, () => {
                    next();
                });
            } else if (code == 0) {
                next();
            }
        });
    },
    pressKeyLogin(e) {
        var keyCode = e.keyCode;
        var passWordLogin = $('._loginPasswordBtn');
        var codeLogin = $('._loginCodeBtn');

        if (keyCode == 13) {
            if (!passWordLogin.is(':hidden')) {
                passWordLogin.click();
            } else if (!codeLogin.is(':hidden')) {
                codeLogin.click();
            }
        }
    },
    goback() {
        document.referrer ? window.history.go(-1) : window.location.href = '/';
    },
    changeLoginType() {
        $('._passwordContent').toggleClass('f-hide');
        $('._saoCodeLogin').toggleClass('f-hide');

        $('._inputFocus').val('')
            .parent('._changeStatus')
            .removeClass('z-active-pink z-active-blue');
    },
    downloadApp() {
        $('.app-download').toggleClass('app-download-show');
        $('.open-download').toggleClass('f-hide');
        $('.close-download').toggleClass('f-hide');
    },
    Base64() {
        let _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

        // public method for encoding
        this.encode = function (input) {
            var output = "";
            var chr1,
                chr2,
                chr3,
                enc1,
                enc2,
                enc3,
                enc4;
            var i = 0;

            input = utf8Encode(input);

            while (i < input.length) {
                chr1 = input.charCodeAt(i++);
                chr2 = input.charCodeAt(i++);
                chr3 = input.charCodeAt(i++);
                enc1 = chr1 >> 2;
                enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
                enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
                enc4 = chr3 & 63;

                if (isNaN(chr2)) {
                    enc3 = enc4 = 64;
                } else if (isNaN(chr3)) {
                    enc4 = 64;
                }

                output = output +
                    _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
                    _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
            }

            return output;
        }

        // public method for decoding
        this.decode = function (input) {
            var output = "";
            var chr1, chr2, chr3;
            var enc1, enc2, enc3, enc4;
            var i = 0;

            input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

            while (i < input.length) {
                enc1 = _keyStr.indexOf(input.charAt(i++));
                enc2 = _keyStr.indexOf(input.charAt(i++));
                enc3 = _keyStr.indexOf(input.charAt(i++));
                enc4 = _keyStr.indexOf(input.charAt(i++));
                chr1 = (enc1 << 2) | (enc2 >> 4);
                chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
                chr3 = ((enc3 & 3) << 6) | enc4;
                output = output + String.fromCharCode(chr1);
                if (enc3 != 64) {
                    output = output + String.fromCharCode(chr2);
                }
                if (enc4 != 64) {
                    output = output + String.fromCharCode(chr3);
                }
            }
            output = utf8Decode(output);

            return output;
        }

        // private method for UTF-8 encoding
        let utf8Encode = function (string) {
            string = string.replace(/\r\n/g, "\n");
            var utftext = "";

            for (var n = 0; n < string.length; n++) {

                var c = string.charCodeAt(n);

                if (c < 128) {
                    utftext += String.fromCharCode(c);
                } else if ((c > 127) && (c < 2048)) {
                    utftext += String.fromCharCode((c >> 6) | 192);
                    utftext += String.fromCharCode((c & 63) | 128);
                } else {
                    utftext += String.fromCharCode((c >> 12) | 224);
                    utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                    utftext += String.fromCharCode((c & 63) | 128);
                }

            }
            return utftext;
        }

        // private method for UTF-8 decoding
        let utf8Decode = function (utftext) {
            var string = "";
            var i = 0;
            var c = c1 = c2 = 0;

            while (i < utftext.length) {
                c = utftext.charCodeAt(i);

                if (c < 128) {
                    string += String.fromCharCode(c);
                    i++;
                } else if ((c > 191) && (c < 224)) {
                    c2 = utftext.charCodeAt(i + 1);
                    string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                    i += 2;
                } else {
                    c2 = utftext.charCodeAt(i + 1);
                    c3 = utftext.charCodeAt(i + 2);
                    string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                    i += 3;
                }
            }

            return string;
        }
    },
    handleGetVoiceCode() {
        let phone = $('._verificationTel').val();
        let code = $('._telCode').val();

        if (!__login.validPhone(phone, code)) {
            __login.loginTipFun($('._verificationTel'), 'wrong', '手机号错误');
            return false;
        }
        fetchRequest({
            url: '/common/sendVoiceCode',
            type: 'post',
            parma: {
                mobile: phone
            },
            successFun(data) {
                if (data.code != 0) {
                    layer.msg(data.msg);
                    return false;
                }
                setTimeout(function () {
                    $('._voiceModal').show();
                }, 300)
                __login.smsType = '2';
            }
        });
    },
    closeModal(){
        $('._voiceModal').hide();
    }
};

$(function () {
    __login.init();

    $(document)
    // 按enter键登录
        .on('keyup', __login.pressKeyLogin)
        // 登录切换
        .on('click', '._loginTab', __login.loginTab)
        // 立即注册点击切换到短信登录
        .on('click', '._changeLoginTab', __login.changeLoginTab)
        // 注册tab切换
        .on('click', '._registerTab span', __login.registerTabChangeFun)
        // 登录条件状态切换
        .on('click', '._conditionChecked', __login.conditionChecked)
        // 密码登录
        .on('click', '._loginPasswordBtn', __login.loginPasswordBtn)
        // 短信登录
        .on('click', '._loginCodeBtn', __login.loginCodeBtn)
        // 发送验证码
        .on('click', '._sendMsgCode', __login.sendMsgCode)
        .on('click', '._voice', __login.handleGetVoiceCode)
        .on('click', '._closeModal', __login.closeModal)
        // 获取焦点清空提示状态
        .on('click', '._changeStatus', __login.changeStatus)
        // 失去焦点判断
        // .on('blur', '._inputBlur', __login.inputBlur)
        .on('focus', '._inputFocus', __login.inputFocus)
        // 注册按钮
        .on('click', '._registerBtn', __login.registerBtn)
        // 点击刷新二维码
        .on('click', '._reloadLoginCode', __login.reloadLoginCode)
        // 找回密码btn
        .on('click', '._findPsdNextBtn', __login.findPsdNextBtn)
        // 绑定手机号
        .on('click', '._bindTelBtn', __login.bindTelBtn)
        // 切换登录方式
        .on('click', '._changeLoginType', __login.changeLoginType)
        // 下载科界
        .on('click', '._downloadApp', __login.downloadApp);
});